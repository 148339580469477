var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"row-clicked":_vm.navigateToItem,"table-config-options":{
      mergedEndpoint: true,
      endpoint: (_vm.MODULE_NAME + "/purchaseCreditList")
    }},scopedSlots:_vm._u([{key:"cell(order_no)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',[_c('span',[_vm._v(" "+_vm._s(item.number ? _vm.getValueFromGivenObjectByKey(item, 'number', '—') : item.id)+" ")])])]}},{key:"cell(state)",fn:function(ref){
    var item = ref.data.item;
return [_c('status-filter-table',{attrs:{"item":item}})]}},{key:"cell(order_date)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(item.order ? _vm.mapDate(_vm.getValueFromGivenObjectByKey(item, 'order.created_at', '—')) : _vm.mapDate(item.created_at))+" ")]}},{key:"cell(supplier)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"4px"}},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'supplier.cust_name_dba', '—'))+" ")])]}},{key:"cell(internal_order_reference)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(item.internal_order_reference ? _vm.getValueFromGivenObjectByKey(item, 'internal_order_reference', '—') : _vm.getValueFromGivenObjectByKey(item, 'credit_note_order', '—'))+" ")]}},{key:"cell(amount)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatNumberToCurrencyView(_vm.getValueFromGivenObjectByKey(item, 'total', 0)))+" ")])]}},{key:"manage-monthly-date",fn:function(){return [_c('manage-monthly-date')]},proxy:true},{key:"status-filter-tab",fn:function(){return [_c('status-filter-tab')]},proxy:true},{key:"filter",fn:function(){return [_c('status-filter-color-key')]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }