<template>
  <div
    class="d-flex"
    style="gap: 8px"
  >
    <b-button class="chevron-btn--custom">
      <feather-icon
        icon="LCreditCard"
        size="21"
        class="iconClass lightIcon m-0"
        @click="gotoNewPage({ name: 'home-orders-credit-note-create-quote' }, $event)"
      />
    </b-button>
    <feather-icon
      icon="LAddButtonGreenIcon"
      size="35"
      class="lightIcon m-0 cursor-pointer"
      @click="gotoNewPage({ name: 'home-orders-purchase-cr-create' }, $event)"
    />
  </div>
</template>

<script>
// Components
import { BButton } from 'bootstrap-vue'

// Configs
import config from '@/views/main/orders/config'

export default {
  name: 'StatusFilterTab',
  components: {
    BButton,
  },
  setup() {
    const { statusFilterTab } = config()

    return {
      statusFilterTab,
    }
  },
}
</script>
<style lang="scss" scoped>
.iconClass{
  fill: #ffffff;
}
.chevron-btn--custom {
  min-width: 36px;
  height: 36px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
  .btn-custom {
    min-width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: #D9D9D9 !important;
    margin: 0;
    transition: all 0.2s ease;

    &--active {
      background-color: #646464 !important;
      transition: all 0.2s ease;
      & svg {
        fill: #fff !important;
      }
    }

    &--LPickIcon, &--LBoxIcon {
      & svg {
        position: relative;
        top: 3px;
      }
    }

    &--clear {
      background: transparent !important;
      & svg {
        fill: #D9D9D9;
      }

      &:hover {
        border-color: rgba(100, 100, 100, 0.8);
        & svg {
          fill: rgba(100, 100, 100, 0.8);
        }
      }
    }
  }
</style>
<style lang="scss">
.paddingCustom {
  border: 1px dashed #dde3e7;
  border-radius: 0.35rem;
  height: 36px;
  min-width: 115px;

  & .btn {
    padding: 7px 15.5px !important;
  }

  .dropdown-item {
    padding: 0.2rem 1.28rem;
  }
}

.dropNewClass{
  outline: none;
  display: flex;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
  color: #49454f;
  gap: 9px;
}
</style>
